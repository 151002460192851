import { CSS } from '@stitches/react';
import KexLink from 'Kex/KexLink';
import ImageModel from 'Models/Assets/ImageModel.interface';
import LinkModel from 'Models/Link/LinkModel.interface';
import { styled } from 'stitches.config';

type PropType = {
  css?: CSS;
  backgroundColor: 'primary' | 'secondary';
  image: ImageModel;
  link: LinkModel;
};

function BrandCard({
  css,
  backgroundColor = 'primary',
  image,
  link,
}: PropType) {
  return (
    <StyledKexLink
      css={css}
      backgroundColor={backgroundColor}
      href={link?.href}
      underlined={false}
    >
      {image && <BrandCardImage src={image.src} alt={link.text} />}
    </StyledKexLink>
  );
}

export default BrandCard;

const StyledKexLink = styled(KexLink, {
  width: '416px',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  objectFit: 'cover',
  marginBottom: 0,
  '@mediaMinLarge': {
    height: '80px',
  },

  variants: {
    backgroundColor: {
      primary: {
        backgroundColor: '$brandCardPrimaryBackgroundColor',
        '&:hover': {
          backgroundColor: '$brandCardPrimaryHoverBackgroundColor',
        },
      },
      secondary: {
        backgroundColor: '$brandCardSecondaryBackgroundColor',
        '&:hover': {
          backgroundColor: '$brandCardSecondaryHoverBackgroundColor',
        },
      },
    },
  },

  defaultVariants: {
    backgroundColor: 'primary',
  },
});

const BrandCardImage = styled('img', {
  width: '88px',
  objectFit: 'cover',
  '@mediaMinLarge': {
    width: '150px',
  },
});
