import Grid from 'Atoms/Grids/Grid';
import { GridSize } from 'Enums/GridSize.enum';
import StartPageModel from 'Models/Pages/StartPage/StartPageModel.interface';
import BrandArea from 'Molecules/BrandArea/BrandArea';

import BrandCardSection from 'Organisms/BrandCardSection/BrandCardSection';
import ContentArea from 'Organisms/ContentArea/ContentArea';
import HeroModule from 'Organisms/Hero/HeroModule';
import { isEmpty } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { styled } from 'stitches.config';

function StartPage() {
  const {
    contentArea,
    inEditMode,
    heroHeader,
    heroImage,
    heroText,
    brandCards,
    brandArea,
  } = useCurrentPage<StartPageModel>();

  return (
    <>
      {heroImage && (
        <HeroModule
          header={heroHeader}
          heroText={heroText}
          inEditMode={inEditMode}
          image={heroImage}
        />
      )}
      {!isEmpty(brandCards) && (
        <BrandCardWrapper>
          <BrandCardSection brandCards={brandCards} />
        </BrandCardWrapper>
      )}
      <Grid noPadding={false} type={GridSize.Twelve}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </Grid>
      <BrandArea brandArea={brandArea} />
    </>
  );
}

export default StartPage;

const BrandCardWrapper = styled('div', {
  position: 'relative',
  top: '-46px',
  '@mediaMinLarge': {
    top: '-53px',
  },
});
