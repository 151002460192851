export enum GridSize {
  Twelve = 12,
  Six = 6,
  Four = 4,
  Three = 3,
  One = 1,
  ContentContainer = 'ContentContainer',
}

export enum GridWidth {
  ContentWidth = 'contentWidth',
  ScreenWidth = 'screenWidth',
  Auto = 'auto',
}
