import BrandCard from 'Atoms/BrandCard/BrandCard';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import BrandCardBlockModel from '../../Models/Blocks/BrandCardBlock/BrandCardBlockModel.interface';

type PropTypes = {
  brandCards: BrandCardBlockModel[];
  backgroundColor?: 'primary' | 'secondary';
};

function BrandCardSection({
  brandCards,
  backgroundColor = 'primary',
}: PropTypes) {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <ContentContainer>
      <Container>
        {brandCards.map((brand, index) => {
          const mobileImgPreset = `${brand.image?.src}?preset=brandcard-mobile`;
          const desktopImgPreset = `${brand.image?.src}?preset=brandcard-desktop`;
          const brandImage = isDesktop ? desktopImgPreset : mobileImgPreset;

          return (
            <BrandCard
              key={index}
              image={{ ...brand.image, src: brandImage }}
              link={brand.link}
              backgroundColor={backgroundColor}
            />
          );
        })}
      </Container>
    </ContentContainer>
  );
}

const Container = styled('div', {
  display: 'flex',
  columnGap: '16px',
  '@mediaMinLarge': {
    columnGap: '32px',
  },
});

export default BrandCardSection;
