import BrandListModel from 'Models/Brand/BrandListModel.interface';
import { styled } from 'stitches.config';
import ScrollContainer from 'react-indiana-drag-scroll';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PropTypes = {
  brandArea: BrandListModel;
};

function BrandArea({ brandArea: { brands = [] } }: PropTypes) {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const isContentWidth = useMedia(mediaQueryTypes.mediaMinGreat);

  const isHorizontalScroll = brands.length > 6 || !isContentWidth;

  return (
    <BrandAreaContainer>
      <BrandAreaWrapper horizontal={isHorizontalScroll}>
        {brands.map((brand, index) => {
          const mobileImgPreset = `${brand.src}?preset=brand-mobile`;
          const desktopImgPreset = `${brand.src}?preset=brand-desktop`;

          const brandImage = isDesktop ? desktopImgPreset : mobileImgPreset;

          return <BrandImage key={index} src={brandImage} alt={''} />;
        })}
      </BrandAreaWrapper>
    </BrandAreaContainer>
  );
}
const BrandAreaContainer = styled('div', {
  w: '100%',
  py: 4,
  ls: '$ls8',
  backgroundColor: '$brandAreaBackground',
  '@mediaMinLarge': {
    py: 0,
  },
});

const BrandAreaWrapper = styled(ScrollContainer, {
  px: 4,
  maxW: '$contentMaxWidth',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '48px',
  flexWrap: 'nowrap',
  '@mediaMinLarge': {
    columnGap: '80px',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const BrandImage = styled('img', {
  h: 16,
  w: 16,
  objectFit: 'cover',
  '@mediaMinLarge': {
    h: 37.5,
    w: 37.5,
  },
});

export default BrandArea;
